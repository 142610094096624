import { appProvider } from "./../../../app-providers";
import { NftModel } from "@/models/nft-model";
import { MoralisModel } from "@/models/moralis-model";
import { moralisHelper } from "@/helpers/moralis-helper";
import { snackController } from "@/components/snack-bar/snack-bar-controller";
import { bigNumberHelper } from "@/helpers/bignumber-helper";
import { walletStore } from "@/stores/wallet-store";
import { FixedNumber } from "@ethersproject/bignumber";
import { action, computed, IReactionDisposer, observable, reaction } from "mobx";
import { asyncAction, task } from "mobx-utils";
import moment from "moment";
import { Zero } from "@/constants";
import { Subject, timer } from "rxjs";
import { ApiService } from "@/service/api-service";
import { loadingController } from "@/components/global-loading/global-loading-controller";
import { reverse } from "lodash";
import { confirmDialogController } from "@/components/confirm-dialog/confirm-dialog-controller";
import Web3 from "web3";
import { blockchainHandler } from "@/blockchain";

const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!;

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on("receipt", (result) => resolve(result))
      .on("error", (error) => reject(error));
  });
}

export class CharacterDetailViewmodel {
  _disposers: IReactionDisposer[] = [];

  @observable isOwner?: boolean = false;
  @observable nftOwner?: string = "";
  @observable nftId?: string = "";
  @observable character?: NftModel = {};

  @observable transferDialog?: boolean = false;
  @observable sellDialog?: boolean = false;
  @observable makeOfferDialog?: boolean = false;
  @observable buyDialog?: boolean = false;

  @observable isTransfering?: boolean = false;
  @observable transfers?: any = [];
  @observable transferPage?: number = 1;
  @observable transferPerPage?: number = 4;

  NFTContract!: any;

  constructor() {
    this.nftId = appProvider.router.currentRoute.params.id;
    this.NFTContract = new web3.eth.Contract(require("@/helpers/erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
    this.loadData();
    this._disposers = [
      reaction(
        () => walletStore.account,
        () => {
          if (walletStore.isChainIdValid) this.loadData();
        }
      ),
    ];
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.NFTContract = new web3.eth.Contract(require("@/helpers/erc721.abi.json"), process.env.VUE_APP_NFT_ADDRESS);
    }
  }

  async getNftOwner() {
    return await this.NFTContract.methods.ownerOf(this.nftId).call();
  }

  @asyncAction *getNftTransfers() {
    const response = yield moralisHelper.getNFTTransfers(
      process.env.VUE_APP_NFT_ADDRESS!,
      this.nftId!,
      process.env.VUE_APP_CHAIN_ID === "97" ? "bsc testnet" : "bsc"
    );
    return response.result;
  }

  @action.bound setTransferDialog(val) {
    this.transferDialog = val;
  }

  @asyncAction *transfer(toAddress) {
    this.isTransfering = true;
    try {
      if (!walletStore.account || !toAddress || !this.nftId) {
        snackController.error("Error occured when making transfer NFT!");
        return;
      }
      const f = this.NFTContract.methods.transferFrom(walletStore.account, toAddress, this.nftId);
      const response = yield sendRequest(f, walletStore.account);
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      this.isTransfering = false;
    }
  }

  @asyncAction
  *loadData() {
    loadingController.increaseRequest();
    try {
      if (walletStore.isChainIdValid) {
        this.injectMetamask(walletStore.web3!);
      }
      const nfts = yield this.checkNftId();
      if (!nfts || nfts.length === 0) {
        snackController.error("Invalid monster Id!");
        appProvider.router.push("/my-character");
        return;
      }
      this.nftOwner = yield this.getNftOwner();
      this.isOwner = this.nftOwner === walletStore.account;
      const transfers = yield this.getNftTransfers();
      this.character = nfts[0];
    } catch (error) {
      console.error(error);
    } finally {
      loadingController.decreaseRequest();
    }
  }

  @asyncAction *checkNftId() {
    return yield appProvider.api.nft.find({
      // eslint-disable-next-line
      ID: this.nftId,
      _limit: 1,
    });
  }

  @action.bound changePage() {
    console.log("page", this.transferPage);
  }

  @computed get slicedTransfers() {
    return this.transfers.slice(
      (this.transferPage! - 1) * this.transferPerPage!,
      this.transferPage! * this.transferPerPage!
    );
  }
  @computed get transferTotalPage() {
    if (this.transfers.length! % this.transferPerPage! == 0) return this.transfer.length! / this.transferPerPage!;
    else return Math.floor(this.transfers.length! / this.transferPerPage!) + 1;
  }
}
