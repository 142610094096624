











































































































































































































import { walletStore } from "@/stores/wallet-store";
import { Observer } from "mobx-vue";
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import { CharacterDetailViewmodel } from "../viewmodels/character-detail-viewmodel";

@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterBody: () => import("@/components/character/character-body.vue"),
    CharacterInfo: () => import("@/components/character/character-info.vue"),
    CharacterStat: () => import("@/components/character/character-stat.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
    SellMonster: () => import("../dialogs/sell-monster-dialog.vue"),
    TransferMonster: () => import("../dialogs/transfer-monster-dialog.vue"),
    CharacterOfferTable: () => import("../components/character-offer-table.vue"),
  },
})
export default class CharacterDetail extends Vue {
  @Provide() vm = new CharacterDetailViewmodel();
  walletStore = walletStore;
  screenWidth = window.innerWidth;

  tab?: number = 0;
  //TODO: Remove this
  offers: any = [];

  openAddressLink(address) {
    window.open(
      `${
        process.env.VUE_APP_CHAIN_ID === "97"
          ? "https://testnet.bscscan.com/address/"
          : "https://bscscan.com/address/"
      }${address}`
    );
  }
  openTransactionLink(hash) {
    window.open(
      `${
        process.env.VUE_APP_CHAIN_ID === "97" ? "https://testnet.bscscan.com/tx/" : "https://bscscan.com/tx/"
      }${hash}`
    );
  }
}
